import { Icon, LegacyCard, Spinner, Text } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import config from "@vendor-app/config";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { colors } from "@vendor-app/constants/colors";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { Category } from "./Category";
import { linkSettings, targetContentLength } from "./constants";
import { SectionContent } from "./Section";

interface RssResponse {
  items: {
    content: string;
    contentSnippet: string;
    link: string;
    isoDate: string;
    title: string;
  }[];
}

const Block = styled.a`
  border-bottom: 1px solid #dfe3e7;
  color: var(--p-color-text);
  display: block;
  padding: 20px 20px;
  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:focus-within {
    background-color: #f3f3f6;
    color: ${colors.offBlackText};
    text-decoration: none;
  }
  &:hover h3,
  &:focus-within h3 {
    color: ${colors.offBlackText};
  }

  > span {
    color: ${colors.helpText};
    font-size: 12px;
    line-height: 14px;
  }

  > h3 {
    color: var(--p-color-text);
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    margin: 5px 0 10px;
  }
`;
const RSSWrapper = styled.div`
  display: block;
  max-height: 599px;
  overflow-x: hidden;
  overflow-y: scroll;
`;
const ChangelogContainer = styled.div`
  margin-top: 15px;

  > * {
    font-size: 12px;
    line-height: 15px;
  }

  a {
    color: var(--p-color-text);
    text-decoration: underline;
  }
`;

const CategoriesBlock = styled.div`
  display: flex;
  gap: 5px;
`;

const Footer = styled.a`
  align-items: center;
  background-color: #f4f6f8;
  border-radius: 0px 0px var(--p-border-radius-2) var(--p-border-radius-2);
  color: #2c6ecb;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  justify-content: center;
  line-height: 17px;
  padding: 6px;
  text-align: center;
  text-decoration: underline;
  & .Polaris-Icon {
    margin: 0;
    margin-left: 3px;
    width: 9px;
    height: 9px;
    & .Polaris-Icon__Svg {
      fill: var(--p-color-icon-interactive);
      width: 9px;
      height: 9px;
    }
  }

  &:hover,
  &:hover .Polaris-Icon__Svg,
  &:focus-within,
  &:focus-within .Polaris-Icon__Svg {
    color: var(--p-color-text-interactive-hover);
    cursor: pointer;
    fill: var(--p-color-icon-interactive-hover);
  }
`;

const SpinnerContainer = styled(SectionContent)`
  display: flex;
  justify-content: center;
`;

const RSSCardHeader = styled.div`
  border-bottom: 1px solid #e4e5e7;
  padding: 20px;
  & h2 {
    margin-bottom: 20px;
  }
`;

export const RSSBlock = ({ rightBlockRef }: { rightBlockRef?: React.RefObject<HTMLDivElement> }) => {
  const [rssData, setRssData] = useState<RssResponse>();

  useEffect(() => {
    typedFrontendVendorApi
      .getReq(`/integrations/headwayapp`)
      .then(resp => resp.type === "success" && setRssData(resp.body));
  }, []);

  return (
    <LegacyCard>
      <RSSCardHeader>
        <Text variant="headingMd" as="h2">
          Changelog
        </Text>
        New feature announcements, improvements, bug fixes, and previews into upcoming releases.
      </RSSCardHeader>
      <RSSWrapper ref={rightBlockRef}>
        {rssData ? (
          rssData.items.map(el => {
            const formattedDate = new Date(el.isoDate).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            const paragraphs = el.contentSnippet.substring(0, targetContentLength).split("\n");
            const targetElIndex = paragraphs.length - 1;
            const wordsInTheLastParagraph = paragraphs[targetElIndex].split(" ");
            const lastFewWordsInParagraph = wordsInTheLastParagraph.slice(-1).join(" ");
            const divEl = document.createElement("div");
            divEl.innerHTML = el.content;

            const customHeadwayCategories = [...el.content.matchAll(/<h3 class='.{1,40}'>(.{1,100})<\/h3>/g)].map(
              el => el[1]
            );

            const childrenNodes = [...divEl.children];
            const addition = childrenNodes[0].innerHTML.startsWith("<br>") ? 2 : 1; // <br> in the beginning cause loosing 1 paragraph (rss-parser problem)
            const shortHtml = childrenNodes
              .slice(0, targetElIndex + addition)
              .map(el => el.outerHTML)
              .join(""); // get necessary paragraphs
            const lastMatchIndex = shortHtml.lastIndexOf(lastFewWordsInParagraph);
            const truncatedResult =
              shortHtml.slice(0, lastMatchIndex + lastFewWordsInParagraph.length) +
              (el.contentSnippet.substring(0, targetContentLength).length === targetContentLength ? "..." : ""); // truncate result

            return (
              <Block key={el.title} href={el.link} {...linkSettings}>
                <span>{formattedDate}</span>
                <h3>{el.title}</h3>
                {!!customHeadwayCategories.length && (
                  <CategoriesBlock>
                    {customHeadwayCategories.map(type => (
                      <Category key={type} type={type} />
                    ))}
                  </CategoriesBlock>
                )}
                <ChangelogContainer
                  dangerouslySetInnerHTML={{
                    __html: truncatedResult
                      // remove empty lines
                      .replaceAll("&nbsp;", "")
                      .replaceAll("<br>", "")
                      //
                      // remove custom categories
                      .replaceAll('<span class="beforeCategories"></span>', "")
                      .replaceAll(/<h3 class=".{1,40}">.{1,100}\/h3>/g, "")
                      .replaceAll('<span class="afterCategories"></span>', "")
                      //
                      .replaceAll(/<p><img.*height=\d{3}.\/><\/p>/g, "") // remove images
                      .replaceAll(
                        /href=".{1,100}"/g,
                        `href="${el.link}" tabindex="-1" target="_blank" rel="noopener noreferrer"`
                      ) // replace links href attribute and make safe
                      .replaceAll(/<script>.*<\/script>/g, ""), // just to make sure it's not here
                  }}
                />
              </Block>
            );
          })
        ) : (
          <SpinnerContainer title="">
            <Spinner />
          </SpinnerContainer>
        )}
      </RSSWrapper>
      <Footer href={config.changelogUrl} {...linkSettings}>
        Full changelog <Icon source={ExternalMinor} color="base" />
      </Footer>
    </LegacyCard>
  );
};
