import z from "zod";

export const SupportFormSchema = z.object({
  firstName: z.string().min(1, "Minimum 1 character.").max(30, "Maximum 30 characters."),
  lastName: z.string().min(1, "Minimum 1 character.").max(30, "Maximum 30 characters."),
  email: z.string().email("Please enter valid email."),
  title: z.string().min(1, "Minimum 1 character.").max(60, "Maximum 60 characters."),
  request: z.string().min(1, "Minimum 1 character.").max(300, "Maximum 300 characters."),
});

export type ISupportForm = z.infer<typeof SupportFormSchema>;
