import React from "react";

export const Share = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 7.70059C10.5 7.31402 10.8134 7.00059 11.2 7.00059C11.5866 7.00059 11.9 7.31402 11.9 7.70059V11.9006C11.9 13.0604 10.9598 14.0006 9.8 14.0006H2.1C0.94016 14.0006 0 13.0604 0 11.9006V4.20059C0 3.04075 0.94016 2.10059 2.1 2.10059H6.3C6.68656 2.10059 7 2.41402 7 2.80059C7 3.18715 6.68656 3.50059 6.3 3.50059H2.1C1.71344 3.50059 1.4 3.81402 1.4 4.20059V11.9006C1.4 12.2871 1.71344 12.6006 2.1 12.6006H9.8C10.1866 12.6006 10.5 12.2871 10.5 11.9006V7.70059Z"
      fill="#888D8A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6004 1.4H9.10039C8.71383 1.4 8.40039 1.08656 8.40039 0.7C8.40039 0.313436 8.71383 0 9.10039 0H13.3004C13.687 0 14.0004 0.313436 14.0004 0.7V4.9C14.0004 5.28656 13.687 5.6 13.3004 5.6C12.9138 5.6 12.6004 5.28656 12.6004 4.9V1.4Z"
      fill="#888D8A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09486 8.89496C5.82143 9.16824 5.3783 9.16824 5.10486 8.89496C4.83158 8.62152 4.83158 8.1784 5.10486 7.90496L12.8049 0.20496C13.0783 -0.06832 13.5214 -0.06832 13.7949 0.20496C14.0681 0.478396 14.0681 0.92152 13.7949 1.19496L6.09486 8.89496Z"
      fill="#888D8A"
    />
  </svg>
);
