import { Button, LegacyCard, LegacyStack, Text, TextField } from "@shopify/polaris";
import { ISupportForm } from "@smartrr/shared/entities/SupportForm";
import React, { useEffect, useState } from "react";

import { SupportFormAccess } from "./SupportFormStore";
import { useToast } from "../Toast/ToastProvider";

interface ScaffoldProps<FormKey extends keyof ISupportForm> {
  children: React.ReactNode;
  label: string;
  formKey: FormKey;
}

const Scaffold = <FormKey extends keyof ISupportForm>({
  formKey,
  label,
  children,
}: React.PropsWithChildren<ScaffoldProps<FormKey>>) => {
  const { info } = SupportFormAccess.useValue<FormKey>(formKey);

  return (
    <LegacyStack vertical spacing="extraTight" key={formKey}>
      <Text as="span" variant="bodyMd">
        {label}
      </Text>
      {children}
      <Text as="span" variant="bodySm" color="critical">
        {info.show ? info.error ?? "" : ""}
      </Text>
      {info.show && info.error ? undefined : (
        <div
          style={{
            height: "1.2rem",
          }}
        />
      )}
    </LegacyStack>
  );
};

const FirstName = () => {
  const { info, set } = SupportFormAccess.useValue("firstName");

  return (
    <Scaffold formKey="firstName" label="First name">
      <TextField
        label="First name"
        autoComplete="on"
        labelHidden
        value={info.value}
        placeholder="Enter first name"
        onChange={e => set(e)}
        id="support-form-input-first-name"
      ></TextField>
    </Scaffold>
  );
};

const LastName = () => {
  const { info, set } = SupportFormAccess.useValue("lastName");

  return (
    <Scaffold formKey="lastName" label="Last name">
      <TextField
        label="Last name"
        autoComplete="on"
        labelHidden
        value={info.value}
        placeholder="Enter last name"
        onChange={e => set(e)}
        id="support-form-input-last-name"
      ></TextField>
    </Scaffold>
  );
};

const Email = () => {
  const { info, set } = SupportFormAccess.useValue("email");

  return (
    <Scaffold formKey="email" label="Email">
      <TextField
        label="Email"
        type="email"
        autoComplete="on"
        labelHidden
        value={info.value}
        placeholder="Enter email"
        onChange={e => set(e)}
        id="support-form-input-email"
      ></TextField>
    </Scaffold>
  );
};

const IssueTitle = () => {
  const { info, set } = SupportFormAccess.useValue("title");

  return (
    <Scaffold formKey="title" label="Issue title">
      <TextField
        label="Issue title"
        autoComplete="off"
        labelHidden
        value={info.value}
        placeholder="Enter issue"
        onChange={e => set(e)}
        id="support-form-input-issue-title"
      ></TextField>
    </Scaffold>
  );
};

const Details = () => {
  const { info, set } = SupportFormAccess.useValue("request");

  return (
    <Scaffold formKey="request" label="Details of request">
      <TextField
        label="Details of request"
        autoComplete="off"
        labelHidden
        value={info.value}
        placeholder="Enter details of request"
        onChange={e => set(e)}
        multiline={5}
        id="support-form-input-issue-details"
      ></TextField>
    </Scaffold>
  );
};

const SubmitForm = () => {
  const hasErrors = SupportFormAccess.useHasErrors();
  const actions = SupportFormAccess.useActions();

  const [submitting, setSubmitting] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    actions.validate();
  }, []);

  const submit = async () => {
    setSubmitting(true);
    const result = await actions.submit();
    if (result.result === "success") {
      addToast("Form submitted, we'll be in touch soon");
    } else {
      addToast("Error submitting form, please try again");
    }
    setSubmitting(false);
    actions.initialize();
    actions.validate();
  };

  return (
    <Button loading={submitting} disabled={hasErrors} onClick={() => submit()} primary>
      Submit
    </Button>
  );
};

const SupportForm = () => {
  return (
    <LegacyStack vertical spacing="extraTight">
      <LegacyStack alignment="center" distribution="fillEvenly">
        <FirstName />
        <LastName />
      </LegacyStack>
      <Email />
      <IssueTitle />
      <Details />
      <SubmitForm />
    </LegacyStack>
  );
};

export const SupportFormContainer = () => {
  return (
    <LegacyCard title="Request Form">
      <LegacyCard.Section>
        <LegacyStack vertical>
          <Text as="span" variant="bodyMd">
            Do you have a suggestion on something we can be doing better? Are we missing a key feature that would
            help your business? We&apos;re all ears.
          </Text>
          <SupportForm />
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
};
