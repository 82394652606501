import { ISupportForm, SupportFormSchema } from "@smartrr/shared/entities/SupportForm";
import { validateDataWithZodSchema } from "@smartrr/shared/utils/validateDataWithZodSchema";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { create } from "zustand";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { WithResult } from "../../Sequential/SequentialStore";

type FormErrors<Type> = {
  [k in keyof Type]: string | null;
};

type FormShowErrors<Type> = {
  [k in keyof Type]: boolean;
};

interface ISupportFormStore {
  form: ISupportForm;
  errors: FormErrors<ISupportForm>;
  showErrors: FormShowErrors<ISupportForm>;
  actions: {
    initialize(): void;
    validate(): void;
    submit(): Promise<WithResult<{}>>;
  };
  internal: {
    hasErrors(): boolean;
    setValue<Key extends keyof ISupportForm>(key: Key, value: ISupportForm[Key]): void;
  };
}

const initialState = (): Omit<ISupportFormStore, "actions" | "internal"> => {
  return cloneDeep({
    form: {
      email: "",
      firstName: "",
      lastName: "",
      title: "",
      request: "",
    },
    errors: {
      email: null,
      firstName: null,
      lastName: null,
      title: null,
      request: null,
    },
    showErrors: {
      email: false,
      firstName: false,
      lastName: false,
      title: false,
      request: false,
    },
  });
};

const useSupportFormStore = create<ISupportFormStore>()((set, get) => ({
  form: initialState().form,
  errors: initialState().errors,
  showErrors: initialState().showErrors,
  actions: {
    initialize(): void {
      set({
        form: initialState().form,
        errors: initialState().errors,
        showErrors: initialState().showErrors,
      });
    },

    validate(): void {
      const result = validateDataWithZodSchema(SupportFormSchema, get().form);
      if (result.success) {
        set({ errors: initialState().errors });
      } else {
        set({ errors: initialState().errors });
        for (const issue of result.error.issues) {
          const formKey = issue.path[0];
          if (formKey) {
            set({
              errors: {
                ...get().errors,
                [formKey]: issue.message,
              },
            });
          }
        }
      }
    },

    async submit(): Promise<WithResult<{}>> {
      const store = get();
      store.actions.validate();

      if (store.internal.hasErrors()) {
        return {
          result: "failure",
          message: "Form has errors.",
        };
      }

      const result = await typedFrontendVendorApi.postReq("/support/tickets", {
        reqBody: store.form,
      });

      if (result.type === "error") {
        return {
          result: "failure",
          message: result.message,
        };
      }

      return {
        result: "success",
        data: {},
      };
    },
  },

  internal: {
    setValue<Key extends keyof ISupportForm>(key: Key, value: ISupportForm[Key]): void {
      set({
        form: {
          ...get().form,
          [key]: value,
        },
        showErrors: {
          ...get().showErrors,
          [key]: true,
        },
      });
      get().actions.validate();
    },
    hasErrors(): boolean {
      const errors = get().errors;
      return !!Object.keys(errors).find(key => {
        return errors[key] !== null;
      });
    },
  },
}));

export const SupportFormAccess = {
  useHasErrors() {
    const internal = useSupportFormStore(state => state.internal);
    const errors = useSupportFormStore(state => state.errors);

    return useMemo(() => {
      return internal.hasErrors();
    }, [errors]);
  },

  useActions() {
    return useSupportFormStore(state => state.actions);
  },

  useValue<Key extends keyof ISupportForm>(key: Key) {
    const internal = useSupportFormStore(state => state.internal);

    const value = useSupportFormStore(state => state.form[key]);
    const error = useSupportFormStore(state => state.errors[key]);
    const showError = useSupportFormStore(state => state.showErrors[key]);

    return {
      info: {
        value,
        error,
        show: showError,
      },
      set: (value: ISupportForm[Key]) => internal.setValue<Key>(key, value),
    };
  },
};

export { useSupportFormStore as useSupportFormStoreForTesting };
