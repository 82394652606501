import { Text } from "@shopify/polaris";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { Share } from "@vendor-app/app/_sharedComponents/Icons/Share";
import { colors } from "@vendor-app/constants/colors";

export const Section = styled.div`
  background-color: #ffffff;
  border: 1px solid ${colors.srokeGray};
  border-radius: 4px;
  display: block;
  overflow: auto;

  & + & {
    margin-top: 2rem;
  }

  & + .Polaris-LegacyCard {
    margin-top: 2rem;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.navBlue};
    border-radius: 4px;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #dfe3e7;
  padding: 30px;
  position: relative;

  > h2 {
    color: ${colors.offBlackText};
    margin-bottom: 10px;
  }
`;

const Link = styled.a`
  color: ${colors.helpText};
  display: block;
  position: absolute;
  right: 30px;
  top: 30px;

  &:hover,
  &:focus-within {
    color: ${colors.helpText};
    text-decoration: underline;
  }
`;

export const SectionContent = ({
  title,
  children,
  link,
  linkText,
  className,
}: {
  title: string;
  children: ReactNode;
  link?: string;
  linkText?: ReactNode;
  className?: string;
}) => {
  return (
    <Header className={className}>
      <Text variant="headingMd" as="h2">
        {title}
      </Text>
      {!!link && !!linkText && (
        <Link href={link} target="_blank" rel="noopener noreferrer">
          {linkText} <Share />
        </Link>
      )}
      {children}
    </Header>
  );
};
