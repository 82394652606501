import styled from "styled-components";

import { colors } from "@vendor-app/constants/colors";

export const targetContentLength = 500;
export const linkSettings = { target: "_blank", rel: "noopenere noreferrer" };

export const Description = styled.span`
  color: ${colors.helpText};
  font-size: 14px;
  line-height: 18px;
`;

export const List = styled.ol`
  color: #3773d9;
  margin-left: 17px;

  a {
    color: #3773d9;
  }
`;

export const links = [
  {
    text: "What is Smartrr's white-glove onboarding process?",
    link: "https://help.smartrr.com/docs/getting-started/onboarding/what-is-smartrrs-white-glove-onboarding-process",
  },
  {
    text: "How do I set up Subscription Programs?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-subscription-programs",
  },
  {
    text: "How can I customize the theme of my store's Account Portal",
    link: "https://help.smartrr.com/docs/support/admin-portal/how-can-i-customize-the-theme-of-my-stores-account-portal",
  },
  {
    text: "How do I set up subscription discount codes?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-subscription-discount-codes",
  },
  {
    text: "How do customers access their Account Portal?",
    link: "https://help.smartrr.com/docs/support/account-portal/how-do-customers-access-their-account-portal",
  },
  {
    text: "How do I view and adjust my customers' subscriptions?",
    link: "https://help.smartrr.com/docs/support/admin-portal/how-do-i-view-and-adjust-my-customers-subscriptions",
  },
  {
    text: "How do I offer prepaid subscriptions?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-offer-prepaid-subscriptions",
  },
  {
    text: "How do I set up shipping methods for subscription products?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-shipping-methods-for-subscription-products",
  },
  {
    text: "What are subscription anchor dates?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/what-are-subscription-anchor-dates",
  },
  {
    text: "How do I set up email subscription notifications?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-email-subscription-notifications",
  },
];
