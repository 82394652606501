import React, { memo } from "react";
import styled from "styled-components";

const Pill = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 20px;
  color: ${({ color }) => (color == "#202223" ? "#fff" : "#202223")};
  font-size: 13px;
  height: 20px;
  line-height: 16px;
  padding: 0 8px;
  white-space: nowrap;
`;

export type Categories = "New" | "Improvements" | "Fixes" | "Upcoming" | "Announcement";

const colors: Record<Categories, string> = {
  New: "#FAFFBD",
  Improvements: "#B7ECFF",
  Fixes: "#C3FFAE",
  Upcoming: "#F7DAFF",
  Announcement: "#202223",
};

interface Props {
  type: string;
}

export const Category = memo(({ type }: Props) => {
  const colorKey = Object.keys(colors).find(e => type.replaceAll(/[^A-Za-z]*/g, "").includes(e));
  return (
    <Pill data-testid="headway-rss-category" color={colors[colorKey as Categories]}>
      {type}
    </Pill>
  );
});
